
.swiper-slide {
    /* text-align: center; */
    /* font-size: 18px; */
    /* background: #fff; */
  
    /* Center slide text vertically */
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    cursor:pointer; */
    /* width:200px !important; */
  }
  
  /* .swiper-slide img {
    display: block;
    width: 200px;
    height: 200px;
    object-fit: cover;
  } */

  /* .swiper-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  } */

  /* .mySwipe{
    border:2px solid black;
    display: flex !important;
    

  } */













  #root { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  height: calc((100% - 30px) / 2);
  background-size: cover !important;
  /* cursor: pointer; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}


.MuiInputBase-input ,.MuiOutlinedInput-input , 
.MuiInputBase-inputAdornedEnd , .MuiOutlinedInput-inputAdornedEnd ,
.MuiInputBase-inputMarginDense .MuiOutlinedInput-inputMarginDense{
  border-bottom: none !important;
 height:28px !important;
 padding-left:15px !important;
 padding-top:5px !important;
}




input:not([type]):focus:not([readonly]), 
input[type=text]:not(.browser-default):focus:not([readonly]),
 input[type=password]:not(.browser-default):focus:not([readonly]), 
 input[type=email]:not(.browser-default):focus:not([readonly]), 
 input[type=url]:not(.browser-default):focus:not([readonly]), 
 input[type=time]:not(.browser-default):focus:not([readonly]), 
 input[type=date]:not(.browser-default):focus:not([readonly]), 
 input[type=datetime]:not(.browser-default):focus:not([readonly]), 
 input[type=datetime-local]:not(.browser-default):focus:not([readonly]), 
 input[type=tel]:not(.browser-default):focus:not([readonly]), 
 input[type=number]:not(.browser-default):focus:not([readonly]), 
 input[type=search]:not(.browser-default):focus:not([readonly]), 
 textarea.materialize-textarea:focus:not([readonly]){
  border-bottom:none !important;
  box-shadow:none !important;

}

  

  
  